import { useState, useEffect, FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';

type TPortal = {
  children: ReactNode;
  className?: string;
};

const Portal: FC<TPortal> = ({ children, className }) => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    if (container && className && !container.classList.contains(className)) {
      container.classList.add(className);
    }
  }, [className, container]);

  useEffect(() => {
    const parent = document.body;
    parent.appendChild(container);
    return () => {
      if (parent.contains(container)) {
        parent.removeChild(container);
      }
    };
  }, []);

  return ReactDOM.createPortal(children, container);
};

export default Portal;
