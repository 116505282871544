import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Ttarget } from 'domains/campaign/types';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import {
  RGetList,
  RGetSettings,
  RGetStatisticTarget,
  RSearchGeo,
  TargetKey,
  TGetListTargetGroupData,
  TPopular,
  Tree,
} from './model/types';
import { omitUtils } from '../../utils/omit';

export interface TargetState {
  list: FetchedData<RGetList>;
  settings: Record<string, FetchedData<RGetSettings>>;
  auModalCatalog: Record<string, FetchedData<RGetSettings>>;
  statisticTarget: Record<string, FetchedData<RGetStatisticTarget>>;
  geoTree: Record<string, FetchedData<Tree[]>>;
  geoPopular: Record<string, FetchedData<TPopular[]>>;
  setRequest: Record<TargetKey, FetchedData<boolean>>;
  geoSearchGeoData: FetchedData<RSearchGeo>;
  targetUserSettingsData: FetchedData<Record<TargetKey, Ttarget>>;
  targetGroups: FetchedData<TGetListTargetGroupData[]>;
}
export const initialState: TargetState = {
  list: genFetchedData<RGetList>(null),
  settings: {},
  auModalCatalog: {},
  statisticTarget: {},
  geoTree: {},
  geoPopular: {},
  setRequest: {} as Record<TargetKey, FetchedData<boolean>>,
  geoSearchGeoData: genFetchedData<RSearchGeo>(null),
  targetUserSettingsData: genFetchedData<Record<TargetKey, Ttarget>>(null),
  targetGroups: genFetchedData<TGetListTargetGroupData[]>(null),
};
const targetSlice = createSlice({
  name: 'target',
  initialState,
  reducers: {
    setList(state, action: PayloadAction<TargetState['list']>) {
      state.list = action.payload;
    },
    delList(state) {
      state.list = initialState.list;
    },
    setAuModalCatalog(state, action: PayloadAction<TargetState['settings']>) {
      state.auModalCatalog = action.payload;
    },
    setSettings(state, action: PayloadAction<TargetState['settings']>) {
      state.settings = action.payload;
    },
    delSettings(state) {
      state.settings = initialState.settings;
    },

    setStatisticTarget(
      state,
      action: PayloadAction<TargetState['statisticTarget']>,
    ) {
      state.statisticTarget = action.payload;
    },
    delStatisticTarget(state) {
      state.statisticTarget = initialState.statisticTarget;
    },
    setGeoTree(state, action: PayloadAction<TargetState['geoTree']>) {
      state.geoTree = action.payload;
    },
    resetGeoTree(state) {
      state.geoTree = initialState.geoTree;
    },
    setGeoPopular(state, action: PayloadAction<TargetState['geoPopular']>) {
      state.geoPopular = action.payload;
    },
    setTargetingRequest(
      state,
      action: PayloadAction<TargetState['setRequest']>,
    ) {
      state.setRequest = action.payload;
    },
    setGeoSearchData(
      state,
      action: PayloadAction<TargetState['geoSearchGeoData']>,
    ) {
      state.geoSearchGeoData = action.payload;
    },
    resetGeoSearchData(state) {
      state.geoSearchGeoData = initialState.geoSearchGeoData;
    },
    resetTargetingRequest(state, action: PayloadAction<TargetKey>) {
      state.setRequest = {
        ...omitUtils<TargetState['setRequest']>(
          state.setRequest,
          action.payload,
        ),
      };
    },
    delAllRequest(state) {
      state.setRequest = initialState.setRequest;
    },
    setTargetUserSettingsData(
      state,
      action: PayloadAction<TargetState['targetUserSettingsData']>,
    ) {
      state.targetUserSettingsData = action.payload;
    },
    setTargetGroups(state, action: PayloadAction<TargetState['targetGroups']>) {
      state.targetGroups = action.payload;
    },
  },
});

export const {
  setList,
  delList,
  setSettings,
  setAuModalCatalog,
  delSettings,
  setStatisticTarget,
  delStatisticTarget,
  setGeoTree,
  resetGeoTree,
  setGeoPopular,
  setTargetingRequest,
  resetTargetingRequest,
  delAllRequest,
  setGeoSearchData,
  resetGeoSearchData,
  setTargetUserSettingsData,
  setTargetGroups
} = targetSlice.actions;
export const targetReducer = targetSlice.reducer;
