import request from '../../../lib/helpers/requestApi';
import { GeoSearchParams, RGeo, RGeoPopular, RSearchGeo, TGetListTargetGroupData } from './types';

/** метод получчает общее дерево стран и регионов и городов */
export const getGeoTree = request<RGeo>({
  method: 'geo.getTree',
});

/** метод получает список полулярных городов или стран */
export const getAllPopular = request<RGeoPopular>({
  method: 'geo.getAllPopular',
});

/** метод проводит поиск на бэке по дереву городов */
export const getSearchGeo = request<RSearchGeo, GeoSearchParams>({
  method: 'geo.searchBy',
});

/** Получение списка групп таргетов */
export const getListTargetGroupAPI = request<TGetListTargetGroupData>({
  method: 'targeting.getListTargetGroup',
});