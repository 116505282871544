import { createAction } from '@reduxjs/toolkit';
import { ToastUtilsSuccessParams } from 'types/general';
import {
  PGetSettings,
  PGetStatisticTarget,
  PSetTargeting,
  PSetTargetingCampaign,
} from './model/types';
import { ClearAction, DateRange, RemoveUserSet } from './types';

export const fetchGetList = createAction<string>('@@target/fetchPersonalInfo');
export const fetchGetListInCreative = createAction<string>(
  '@@target/fetchGetListCreative',
);
export const fetchClearTarget = createAction<{
  xxhash: string;
  alert: string | ToastUtilsSuccessParams;
}>('@@target/fetchClearTarget');

export type PFetchTargetData = PGetStatisticTarget &
  PGetSettings & { isStatistics: boolean; isSettings: boolean };
export const fetchTargetData = createAction<PFetchTargetData>(
  '@@target/fetchTargetData',
);
type TSettingsAction = {
  targetName: string;
  campaign_xxhash: string;
};
export const fetchSettings = createAction<TSettingsAction>(
  '@@target/fetchSettings',
);

export const updateAu = createAction<TSettingsAction & {
  callbacks: {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setNotFound: React.Dispatch<React.SetStateAction<string[]>>;
  }
}>(
  '@@target/updateAu',
);

export const updateTarget = createAction<TSettingsAction>(
  '@@target/updateTarget',
);

type TStatisticAction = {
  targetName: string;
  xxhash: string;
  period: DateRange;
};
export const fetchStatistics = createAction<TStatisticAction>(
  '@@target/fetchStatistics',
);

export const onSetTargeting = createAction<PSetTargetingCampaign>(
  '@@target/onSetTargeting',
);

export const clearAllTargetItems = createAction<ClearAction>(
  '@@target/clearAllTargetItems',
);

export const removeUserSettings = createAction<RemoveUserSet>(
  '@@target/removeUserSettingAPI',
);

type ToggleInheritAction = PSetTargeting & {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const toggleInheritSettings = createAction<ToggleInheritAction>(
  '@@target/toggleInheritSettings',
);
