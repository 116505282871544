import { createAction } from '@reduxjs/toolkit';
import { GeoSearchParams, TargetKey } from './types';

export const fetchGeoTree = createAction('target/fetchGeoTree');

export const fetchGeoPopular= createAction('target/fetchGeoPopular');

export const resetTargetType = createAction<string>('target/resetTargetType');

export const geoSearch = createAction<GeoSearchParams>('target/geoSearch');

export const fetchMyTargetGeo = createAction<TargetKey>(
  'target/fetchMyTargetGeo',
);

export const getListTargetGroup = createAction(
  '@@target/getListTargetGroup',
);
